<template>
  <main class="main_v8 mts_monitoring">
    
    <div class="table_hat">
      <h3>MTS Monitoring</h3>
      
      <div class="tagselectpanel">
        <tagSelectDialog :src="vtype" @selected="vehicleSelected"></tagSelectDialog>
      </div>
    </div>

    <div class="table_monitor">
      <div class="data_table">
        <div class="info_buttons" v-dragscroll>
          <div class="info_button" :style="[category == 'MachineStatus' ? { backgroundColor: '#f2f2f2' } : null]"
          @click="switchTo('MachineStatus')">Machine Status</div>
          <div class="info_button" :style="[category == 'Compliance' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('Compliance')">Compliance</div>
          <div class="info_button" :style="[category == 'MastHydraulic' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('MastHydraulic')">Mast-Hydraulic</div>
          <div class="info_button" :style="[category == 'LVC' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('LVC')">LVC</div>
          <div class="info_button" :style="[category == 'AP' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('AP')">A-P</div>
          <div class="info_button" :style="[category == 'Engine' ? { backgroundColor: '#f2f2f2' } : null]"
            @click="switchTo('Engine')">Engine</div>
        </div>
        <br>
        <livedata
          :src="url"
          :scanrate="scanrate"
          :controls=false
          @on-data="updatedata">
        </livedata>
        <div style="font-size: 1rem;" v-if="loadingData">Please wait while loading new data...</div>
        
        <div class="table_list">
          <template v-for = "(group, groupKey) in data" :key="groupKey">
            <div class="actual_table" v-if="!loadingData && locationid!='' && vehicleid!=0" >
              <template v-for = "(value, key) in group" :key="key">
                <div>
                  <span class="first_col">{{ key }}</span>
                  <span class="second_col" v-if="value['type']=='int16' || value['type']=='int32'">{{ value["value"] + " " + value["units"] }}</span>
                  <span class="second_col" v-if="value['type']=='bool' && value['value']>0">ON</span>
                  <span class="second_col" v-if="value['type']=='bool' && value['value']==0">OFF</span>
                </div>
              </template>
            </div>
          </template>
        </div>

      </div>
    </div>
  </main>
</template>
  
<script setup>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  const store = useStore();
  import livedata from '../components/livedata/data.vue';
  import tagSelectDialog from '../components/boeing/tagSelector2.vue';
  import '../components/boeing/assets/monitoring.scss';
  
  let data = ref();
  const vtype = ref("MTS");
  let loadingData = ref(false);
  let locationid = ref("");
  let vehicleid = ref(0);
  let category = ref("MachineStatus");
  const scanrate = ref(5000);
  const urlbase = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/equipDisplayv8.php?";
  let url = ref(urlbase + "groupName=Compliance&droplet=100");
  let gdroplet = 100;
  let locations = {
    "LCL1": {
      id: 110,
      loc: "NGO",
    },
    "LCL3": {
      id: 111,
      loc: "NGO",
    },
    "MTS1": {
      id: 100,
      loc: "NGO",
    },
    "MTS4": {
      id: 101,
      loc: "NGO",
    },
    "LCL2": {
      id: 210,
      loc: "TAR",
    },
    "LCL4": {
      id: 211,
      loc: "TAR",
    },
    "MTS2": {
      id: 200,
      loc: "TAR",
    },
    "MTS3": {
      id: 201,
      loc: "TAR",
    },
    "LCL5": {
      id: 310,
      loc: "IAB",
    },
    "LCL7": {
      id: 311,
      loc: "IAB",
    },
    "MTS5": {
      id: 300,
      loc: "IAB",
    },
    "MTS7": {
      id: 301,
      loc: "IAB",
    },
    "LCL6": {
      id: 410,
      loc: "PAE",
    },
    "LCL8": {
      id: 411,
      loc: "PAE",
    },
    "MTS6": {
      id: 400,
      loc: "PAE",
    },
    "MTS9": {
      id: 401,
      loc: "PAE",
    },
    "LCL9": {
      id: 500,
      loc: "CHS",
    },
    "LCL10": {
      id: 501,
      loc: "CHS",
    },
    "MTS8": {
      id: 510,
      loc: "CHS",
    },
    "MTS10": {
      id: 511,
      loc: "CHS",
    }
  };

  function updatedata(val) {
    if(vehicleid.value>0) {
        data.value = val;
        loadingData.value = false;
    }
  }

  function switchTo(c) {
     loadingData.value = true;
     category.value = c;
     url.value = urlbase + "groupName="+ category.value +"&droplet="+vehicleid.value;
  }
  
  function vehicleSelected(v) {
    loadingData.value = true;
    locationid.value = v.location;
    vehicleid.value = v.droplet;
    store.state.operationsLocation = v.vehicle;
    // console.log(v.vehicle, 'v.vehicle MTS4');
    url.value = urlbase + "groupName=" + category.value + "&droplet=" + vehicleid.value;
  }
  </script>
  
  <style lang="scss" scoped>

  </style>